import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Ours
import unesc from '../utils/unescape'
import filterRelatedProjects from '../utils/filter-related-projects'
import formatScopeOfWork from '../utils/format-scope-of-work'
import getCtaIdFromSelect from '../utils/get-ctaid-from-select'
import SEO from '../components/SEO'
import { BlockWrapper } from '../components/MainBlocks'
import { H2 } from '../components/Headings'
import ImgSharp from '../components/ImgSharp'
import { StickyContainer, Sticky } from '../components/Sticky'
import RelatedProjects from '../components/RelatedProjects'
import CallToAction from '../components/CallToAction'

const ProjectMeta = props => {
  return (
    <div className="bg-magenta white px2 py1 col-12">
      {props.meta.map((item, index) => {
        if (!item || !item.content) {
          return null
        }

        let keyStr = `ProjectMeta_${item.name}_${index}`

        return (
          <div key={keyStr} className="py1">
            <strong className="uppercase">{item.name}</strong>
            {Array.isArray(item.content) === true ? (
              <ul className="list-style-none m0 p0">
                {item.content.map((subitem, j) => {
                  return <li key={`${keyStr}_${j}`}>{subitem}</li>
                })}
              </ul>
            ) : (
              <div>{item.content}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const ProjectGrid = props => {
  return (
    <div
      className="ProjectGrid  grid-gapx1"
      style={{
        gridTemplateColumns: '1fr 1fr',
        // gridRowsAuto: `minmax(100px, auto)`
      }}>
      {props.children}
    </div>
  )
}

ProjectGrid.defaultProps = {}
ProjectGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}

const GridItemImage = props => <div className="GridItemImage" {...props} />
const GridDivFiller = props => {
  return (
    <div
      className={`GridDivFiller bg-silver ${
        props.last ? 'border-bottom-line' : ''
      }`}
    />
  )
}

GridDivFiller.defaultProps = { last: false }

const GridItemContent = props => {
  let { children, breakpoint, last, ...remainingProps } = props
  let classes = [`GridItemContent`, `clearfix`, `bg-silver`]

  if (!last) {
    classes.push(`md-mb0`)
  } else {
    classes.push('md-pb4')
    classes.push('border-bottom-line')
  }

  return (
    <div className={classes.join(' ')} {...remainingProps}>
      <StickyContainer>
        <div className="px2 lg-px4 lg-max-width-3 mx-auto relative height-100">
          <Sticky className="lg-py4 lg-mt3" breakpoint="lg">
            <div className="lg-pt2">{props.children}</div>
          </Sticky>
        </div>
      </StickyContainer>
    </div>
  )
}

GridItemContent.defaultProps = {
  last: false,
  even: false,
}

GridItemContent.propTypes = {
  last: PropTypes.bool,
}

const GridImagePair = props => {
  let imgClassName = 'block m0 col-12'
  let children = props.children

  if (!Array.isArray(children)) {
    children = [props.children, false]
  }

  let firstImg = children[0]
  let secondImg = children[1]

  return (
    <React.Fragment>
      {firstImg ? (
        <GridItemImage>
          <ImgSharp {...firstImg} className={imgClassName} />
        </GridItemImage>
      ) : (
        <GridDivFiller />
      )}
      {secondImg ? (
        <GridItemImage>
          <ImgSharp {...secondImg} className={imgClassName} />
        </GridItemImage>
      ) : (
        <GridDivFiller />
      )}
    </React.Fragment>
  )
}

class WPProject extends React.Component {
  constructor(props) {
    super(props)
    const data = props.data
    const project = data.project
    const projectDetails = project.projectDetails
    let gallery = project.projectMainContent.gallery
    let hasGallery = gallery && gallery.length >= 1
    let projectContent = project.projectMainContent.projectContent
    let hasContent = projectContent && projectContent.length >= 1
    let gridItemInnerClassName =
      'clearfix mx-auto font-weight-400 md-font-weight-200 h5 line-height-4 md-h4 md-line-height-3 HProject'
    let imgClassName = 'block m0 col-12'

    let scopeOfWorkList = formatScopeOfWork(
      projectDetails.projectRelatedexpertise,
      projectDetails.projectRelatedexpertiseOther
    )

    let result = [
      // Feature Image, Image 1, Image 2
      <div className="GridItem bg-silver" key="WPProject_1">
        <GridImagePair>
          {project.featuredImage && project.featuredImage.node ? project.featuredImage : null}
          {gallery[0] || null}
        </GridImagePair>
        <GridImagePair>
          {gallery[1] || null}
          {null}
        </GridImagePair>
      </div>,

      // Intro content
      projectContent && projectContent[0] ? (
        <GridItemContent breakpoint="lg" key="WPProject_2">
          <div className="mt2 sm-mt2 lg-mt0">
            <H2 is="h1" mt={0} color="magenta" fontWeight={600}>
              {unesc(project.title)}
            </H2>
          </div>
          <div
            className={gridItemInnerClassName}
            dangerouslySetInnerHTML={{
              __html: projectContent[0].content,
            }}
          />
        </GridItemContent>
      ) : (
        <GridDivFiller />
      ),

      // Image 3, Image 4, Image 5
      <div className="GridItem bg-silver" key="WPProject_3">
        <GridImagePair>
          {gallery[2] || null}
          {gallery[3] || null}
        </GridImagePair>
        <GridImagePair>
          {gallery[4] || null}
          {null}
        </GridImagePair>
      </div>,

      // Primary Meta content
      <GridItemContent breakpoint="lg" key="WPProject_4">
        <div className="mxn2 lg-mx0">
          <ProjectMeta
            meta={[
              // Redundant
              // { name: 'Project', content: project.title },
              { name: 'Client', content: projectDetails.client },
              { name: 'Location', content: projectDetails.location },
              scopeOfWorkList.length >= 1
                ? {
                    name: 'Scope of Work',
                    content: (
                      <React.Fragment>
                        {scopeOfWorkList.join(', ')}
                      </React.Fragment>
                    ),
                  }
                : {},
              { name: 'Year', content: projectDetails.year },
              projectDetails.projectStakeholders
                ? {
                    name: 'Other Stakeholders',
                    content: projectDetails.projectStakeholders.map(
                      (obj, index) => {
                        // obj.key = `Stakeholder_${index}`
                        return obj.name
                      }
                    ),
                  }
                : {},
            ]}
          />
        </div>
      </GridItemContent>,
    ]

    // Number of images per piece of content
    let imgOffset = 3

    // Number of hard-coded images, ie.
    // twice imgOffset less featured_media
    let initialIndex = 5 - imgOffset

    // Get the larger number to use as the counter,
    // gallery less the images we’ve already used
    // Or the projects (times imgOffset to match images)

    let count = Math.max(
      projectContent.length - 1 + 1,
      Math.ceil((gallery.length - 5) / imgOffset)
    )

    if (hasGallery || hasContent) {
      for (let i = 1; i <= count; i++) {
        let contentIndex = i
        let contentItem = projectContent[contentIndex]

        let firstImgIndex = initialIndex + imgOffset * i
        let secondImgIndex = firstImgIndex + 1
        let thirdImgIndex = firstImgIndex + 2

        let firstImg = gallery[firstImgIndex]
        let secondImg = gallery[secondImgIndex]
        let thirdImg = gallery[thirdImgIndex]

        if (!contentItem && !firstImg) {
          break
        }

        if (firstImg) {
          result.push(
            <div className={`GridItem bg-silver`} key={`WPProject_img_${i}`}>
              <GridItemImage>
                <ImgSharp {...firstImg} className={imgClassName} />
              </GridItemImage>
              {secondImg ? (
                <GridItemImage>
                  <ImgSharp {...secondImg} className={imgClassName} />
                </GridItemImage>
              ) : null}
              {thirdImg ? (
                <GridItemImage>
                  <ImgSharp {...thirdImg} className={imgClassName} />
                </GridItemImage>
              ) : null}
            </div>
          )
        } else {
          result.push(
            <GridDivFiller key={`WPProject_FillerImg_${i}`} last={count >= i} />
          )
        }

        if (contentItem) {
          result.push(
            <GridItemContent
              key={`WPProject_content_${i}`}
              breakpoint="lg"
              last={count >= i}>
              <div
                className={gridItemInnerClassName}
                dangerouslySetInnerHTML={{
                  __html: contentItem.content,
                }}
              />
            </GridItemContent>
          )
        } else {
          result.push(
            <GridDivFiller
              key={`WPProject_FillerContent_${i}`}
              last={count === i}
            />
          )
        }
      }
    }

    this.result = result
  }

  render() {
    const props = this.props
    const data = props.data
    const project = data.project

    let limit = 4
    let relatedProjects =
      data.related && data.related.edges
        ? filterRelatedProjects(data.related, project, limit)
        : []

    let chosenCta = getCtaIdFromSelect(project.details)

    return (
      <div className="WPProject">
        <SEO
          title={project.title}
          description={project.details.description}
          featuredImage={project.featuredImage}
        />
        <BlockWrapper maxWidth={false} px={false} py={false}>
          <ProjectGrid>{this.result}</ProjectGrid>
        </BlockWrapper>
        <BlockWrapper maxWidth={6} bg="silver">
          <RelatedProjects edges={relatedProjects} limit={limit} />
        </BlockWrapper>
        <CallToAction ctaId={chosenCta} />
      </div>
    )
  }
}

export default WPProject

export const pageQuery = graphql`
  query WordpressProjectById($id: String!) {
    wp {
      generalSettings {
        title
        description
      }
      acfOptions {
        acfOptions {
          ogUrl
        }
      }
    }

    project: wpProject(id: { eq: $id }) {
      id
      title
      slug
      featuredImage {
        node {
          id
          localFile {
            extension
            childImageSharp {
              id
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      details {
        description
        ctaSelect
      }
      projectMainContent {
        projectContent {
          content
          fieldGroupName
        }
        gallery {
          id
          localFile {
            childImageSharp {
              id
              # TODO Correct size
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      projectDetails {
        year
        location
        client
        projectRelatedexpertise {
          ... on WpExpertise {
            # databaseId
            title
            slug
            parent {
              id
            }
          }
        }
        projectStatus
        projectStakeholders {
          name
        }
      }
    }
    # Limit projects to completed, not same id
    related: allWpProject(
      filter: {
        id: { ne: $id }
        projectDetails: { projectStatus: { eq: "completed" } }
        # Don’t want to limit, so we can filter for related
        # limit: 3
      }
    ) {
      edges {
        node {
          id
          title
          slug
          uri
          details {
            description
          }
          projectDetails {
            location
            projectStatus
            projectRelatedexpertise {
              ... on WpExpertise {
                # databaseId
                parentId
                title
                slug
              }
            }
            # Additional Scope of Work
            projectRelatedexpertiseOther {
              ... on WpProject_Projectdetails_projectRelatedexpertiseOther {
                postTitle
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(width: 1280, height: 1280, cropFocus: CENTER) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
