const formatScopeOfWork = function (
  projectRelatedexpertise,
  projectRelatedexpertiseOther
) {
  let scopeOfWorkUnformatted = []
  let scopeOfWorkList = []

  if (Array.isArray(projectRelatedexpertise)) {
    scopeOfWorkUnformatted = scopeOfWorkUnformatted.concat(
      projectRelatedexpertise
    )
  }

  if (Array.isArray(projectRelatedexpertiseOther)) {
    scopeOfWorkUnformatted = scopeOfWorkUnformatted.concat(
      projectRelatedexpertiseOther
    )
  }

  scopeOfWorkUnformatted.forEach(obj => {
    // Possible for object to be null in development,
    // when you haven’t pulled in all the expertise pages
    if (obj) {
      obj.postTitle = obj.postTitle || obj.title
      if (obj.postTitle && obj.postTitle !== '') {
        // Manually filter out Expertise pagese using hard-coded
        // Expertise page ID. TODO Make this not specific to WP setup!
        if (obj.postParent && obj.postParent !== 102) {
          scopeOfWorkList.push(obj.postTitle)
        }
      }
    }
  })

  return scopeOfWorkList
}

module.exports = formatScopeOfWork
