import React from 'react'
import PropTypes from 'prop-types'

const Sticky = props => {
  let prefix = ''
  if (props.breakpoint) {
    prefix = `${props.breakpoint}-`
  }

  // let style = {
  //   transform: `translateY(-1px)`, // Fixes CSS sticky being 1px off
  // }

  return (
    <div className={`${props.className} ${prefix}sticky top-0 z2`}>
      {props.children}
    </div>
  )
}

Sticky.defaultProps = {
  className: '',
  breakpoint: false,
}

Sticky.propTypes = {
  className: PropTypes.string,
  breakpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const StickyContainer = props => {
  return (
    <div className={`${props.className} height-100 relative col-12`}>
      {props.children}
    </div>
  )
}

StickyContainer.defaultProps = {
  className: '',
}

StickyContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
}

export { Sticky, StickyContainer }
